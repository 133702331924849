import { lazy } from "react";
const User = lazy(() => import("pages/admin"));
const Default = lazy(() => import("pages/default"));
const NotFound = lazy(() => import("pages/notFound"));
const Category = lazy(() => import("pages/categories"));
const Subcategory = lazy(() => import("pages/subcategories"));
const Blog = lazy(() => import("pages/blogs"));
const Guide = lazy(() => import("pages/guide"));
const Notification = lazy(() => import("pages/notification"));
const Catalog = lazy(() => import("pages/catalogs"));
const Service = lazy(() => import("pages/services"));
const Product = lazy(() => import("pages/products"));
const Helper = lazy(() => import("pages/admin"));
const Branch = lazy(() => import("pages/branches"));
const Roles = lazy(() => import("pages/roles"));
const Car = lazy(() => import("pages/cars"));
const Doservice = lazy(() => import("pages/servicesdo"));
const Addservice = lazy(() => import("pages/addService"));
const Users = lazy(() => import("pages/users"));
const Contact = lazy(() => import("pages/contact"));
const Addproduct = lazy(() => import("pages/addProduct"));
const Balance = lazy(() => import("pages/balance"));
const LocalizationPanel = lazy(() => import("pages/localizationPanel"));
const QR = lazy(() => import("pages/qr"));
const Filter = lazy(() => import("pages/filter"));
// const Login = lazy(() => import("pages/login"));

export interface IRoute {
  path: string;
  key?: string | "*";
  element: JSX.Element;
  inner?: IRoute[];
  index?: boolean;
  title: string;
}

const privateRoutes: IRoute[] = [
  {
    path: "/",
    key: "welcome",
    element: <Default />,
    title: "Boshqaruv paneli",
  },
  {
    key: "profile",
    path: "/profile",
    title: "Profile",
    element: <User />,
  },
  {
    key: "categories",
    path: "/categories",
    title: "Kategoriyalar",
    element: <Category />,
  },
  {
    key: "products",
    path: "/products",
    title: "Mahsulotlar",
    element: <Product />,
  },
  {
    key: "blogs",
    path: "/blogs",
    title: "Bloglar",
    element: <Blog />,
  },
  {
    key: "catalogs",
    path: "/catalogs",
    title: "Kataloglar",
    element: <Catalog />,
  },
  {
    key: "subcategories",
    path: "/subcategories",
    title: "Subkategoriyalar",
    element: <Subcategory />,
  },
  {
    key: "notifications",
    path: "/notifications",
    title: "Bildirishnomalar",
    element: <Notification />,
  },
  {
    key: "guidelines",
    element: <Guide />,
    path: "/guidelines",
    title: "Qo'llanmalar",
  },
  {
    key: "services",
    path: "/services",
    title: "Xizmatlar",
    element: <Service />,
  },
  {
    key: "employees",
    title: "Ishchilar",
    path: "/employees",
    element: <Helper />,
  },
  {
    key: "users",
    path: "/users",
    element: <Users />,
    title: "Foydalanuvchilar",
  },
  {
    key: "branches",
    path: "/branches",
    title: "Filiallar",
    element: <Branch />,
  },
  {
    key: "cars",
    path: "/cars",
    element: <Car />,
    title: "Avtomobillar",
  },
  {
    key: "roles",
    path: "/roles",
    title: "Rollar",
    element: <Roles />,
  },
  {
    key: "servicesdo",
    title: "Xizmatlar",
    path: "/servicesdo",
    element: <Doservice />,
  },
  {
    key: "translations",
    title: "Tarjimalar",
    path: "/translations",
    element: <LocalizationPanel />,
  },
  {
    key: "addservice",
    path: "/add-service",
    element: <Addservice />,
    title: "Xizmat qo'shish",
  },
  {
    key: "addproduct",
    path: "/add-product",
    element: <Addproduct />,
    title: "Mahsulot qo'shish",
  },
  {
    key: "balance",
    title: "Balans",
    path: "/balance",
    element: <Balance />,
  },
  {
    title: "Aloqa",
    key: "contacts",
    path: "/contacts",
    element: <Contact />,
  },
  {
    title: "QR",
    key: "QR",
    path: "/qr",
    element: <QR />,
  },
  {
    key: "filter",
    title: "Filter",
    path: "/filter",
    element: <Filter />,
  },
  {
    path: "*",
    key: "*",
    title: "",
    element: <NotFound />,
  },
];

const publicRoutes: IRoute[] = [
  // {
  //   path: "/login",
  //   access: [],
  //   title: "Login",
  //   element: <Login />,
  // },
];

export { privateRoutes, publicRoutes };
