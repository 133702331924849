import {
  AppstoreOutlined,
  FormOutlined,
  BellOutlined,
  CarOutlined,
  TeamOutlined,
  SettingOutlined,
  ToolOutlined,
  AlertOutlined,
  UserAddOutlined,
  AppstoreAddOutlined,
  ContactsOutlined,
} from "@ant-design/icons";

interface MenuItem {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
  route?: string;
}

const menuItems: MenuItem[] = [
  {
    route: "/",
    key: "categories",
    label: "Dashboard",
    icon: <AppstoreOutlined />,
  },
  {
    key: "categories",
    route: "/categories",
    label: "Kategoriyalar",
    icon: <AppstoreAddOutlined />,
  },
  {
    key: "QR",
    route: "/qr",
    label: "QR",
    icon: <AppstoreAddOutlined />,
  },
  {
    key: "filter",
    route: "/filter",
    label: "Filter",
    icon: <AppstoreAddOutlined />,
  },
  {
    key: "subcategories",
    route: "/subcategories",
    label: "Subkategoriyalar",
    icon: <AppstoreAddOutlined />,
  },
  {
    key: "services",
    label: "Xizmatlar",
    route: "/services",
    icon: <SettingOutlined />,
  },
  {
    key: "catalogs",
    route: "/catalogs",
    label: "Kataloglar",
    icon: <AppstoreOutlined />,
  },
  {
    key: "products",
    route: "/products",
    label: "Mahsulotlar",
    icon: <AlertOutlined />,
  },
  {
    key: "servicesdo",
    route: "/servicesdo",
    icon: <ToolOutlined />,
    label: "Doimiy xizmatlar",
  },
  {
    key: "cars",
    route: "/cars",
    label: "Avtomobillar",
    icon: <CarOutlined />,
  },
  {
    key: "users",
    route: "/users",
    label: "Foydalanuvchilar",
    icon: <UserAddOutlined />,
  },
  {
    key: "addservice",
    route: "/add-service",
    label: "Xizmat qo'shish",
    icon: <AppstoreAddOutlined />,
  },
  {
    key: "addproduct",
    route: "/add-product",
    label: "Mahsulot qo'shish",
    icon: <AppstoreAddOutlined />,
  },
  {
    key: "balance",
    label: "Balans",
    route: "/balance",
    icon: <AppstoreAddOutlined />,
  },
  {
    key: "notifications",
    icon: <BellOutlined />,
    route: "/notifications",
    label: "Bildirishnomalar",
  },
  {
    key: "blogs",
    route: "/blogs",
    label: "Bloglar",
    icon: <FormOutlined />,
  },
  {
    key: "employees",
    label: "Ishchilar",
    route: "/employees",
    icon: <TeamOutlined />,
  },
  {
    key: "roles",
    label: "Rollar",
    route: "/roles",
    icon: <ContactsOutlined />,
  },
  {
    key: "contacts",
    label: "Aloqa",
    route: "/contacts",
    icon: <UserAddOutlined />,
  },
  {
    key: "guidelines",
    label: "Qo'llanmalar",
    route: "/guidelines",
    icon: <ContactsOutlined />,
  },
  {
    key: "branches",
    route: "/branches",
    label: "Filiallar",
    icon: <ContactsOutlined />,
  },
  {
    key: "translations",
    label: "Tarjimalar",
    route: "/translations",
    icon: <ToolOutlined />,
  },
];

function gen4() {
  return Math.random()
    .toString(16)
    .slice(-4);
}

export default {
  menuItems,
  // formatStatus,
};

export { gen4 };
